import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Box, useMediaQuery } from '@mui/material'

import HeaderFaqDrawer from '../components/organisms/headerFaqDrawer'
import Footer from '../components/organisms/footer'
import Hero from './scan/hero'
import Tips from './scan/tips'
import Thanks from './scan/thanks'

const Scan = () => {
	const navigate = useNavigate()
	const [scan, setScan] = useState([])
	const isNotMobile = useMediaQuery('(min-width:600px)')

	useEffect(() => {
		getScan()
	}, [])

	useEffect(() => {
	}, [scan])
	let params = useParams()

	const getScan = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/' + params.g, {})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					if (data.display_warning === 0) {
						if (data.url !== '') {
							window.location = data.url;
						}
					}

					if (data.status === 2) {
						navigate('/')
					} else if (data.status === 3) {
						navigate('/register/' + params.g)
					} else {
						localStorage.setItem('scan', JSON.stringify(data))
						setScan(data)
					}
				})
		} catch (error) {
			console.log(error.message)
		}
	}, [])

	return (
		<Box display="flex" flexDirection="column" alignItems="center" sx={{ overflowX: 'hidden', pt: isNotMobile ? 14 : 10 }}>
			<HeaderFaqDrawer user="2" lang="fr" logo="black" backgroundColor="white" />
			<Box>
				<Hero scan={scan} />
				{scan.url_warning !== '' && scan.url_warning !== null && scan.display_warning === 1 &&
					<Thanks scan={scan} />
				}
				<Tips scan={scan} />
			</Box>
			<Footer />
		</Box>
	)
}

export default Scan
