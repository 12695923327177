import React from 'react'
import { useTheme, Box, Typography, useMediaQuery, CardMedia } from '@mui/material'
import OutlinedButton from '../../components/atoms/outlinedButton'
import ContainedButton from '../../components/atoms/containedButton'

const Hero = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotMobile = useMediaQuery('(min-width:600px)')

	return (
		<Box
			className="hero"
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				minHeight: 'calc(100vh - (85px + 2rem))',
				marginTop: `calc(39px + 2rem)`,
				backgroundImage: 'url(/images/waves.png)',
				backgroundRepeat: 'no-repeat',
				// backgroundPosition: 'center 15rem',
				// backgroundSize: 'contain',
				backgroundPosition: 'center 8rem',
				backgroundSize: 'cover',
			}}
		>
			<Box
				m={isNotMobile ? 4 : 2}
				sx={{
					display: 'flex',
					maxWidth: '1120px',
					flexDirection: isNotTablet ? 'row' : 'column',
					gap: isNotTablet ? '' : '5rem',
					alignItems: 'center',
				}}
			>
				<Box
					flex="1"
					display="flex"
					flexDirection="column"
					justifyContent="flex-start"
					gap="1.5rem"
					mb={4}
					sx={{ order: isNotTablet ? 1 : 2 }}
				>
					<Typography variant="h1" sx={{ ml: 2 }}>
						Accepter les pourboires par carte bancaire, c’est facile !
					</Typography>
					<Typography variant="p" sx={{ ml: 2, fontSize: 'clamp(20px,2vw,24px)', lineHeight: 1.3 }}>
						La meilleure solution, sans abonnement, ni commission 😉
					</Typography>
					<Box
						display="flex"
						gap="1rem"
						justifyContent={isNotTablet ? 'flex-start' : 'center'}
						mt={isNotTablet ? 1 : 2}
						sx={{ zIndex: 10 }}
					>
						{isNotTablet && <OutlinedButton href="#easyLife" text="En savoir plus" />}
						<ContainedButton
							href="https://shop.pourliche.fr/"
							target="_blank" 
							text="Accéder au store"
							color={theme.palette.primary.blue}
						/>
					</Box>
				</Box>
				<Box
					flex={isNotTablet ? '1.5' : 1}
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					sx={{ order: isNotTablet ? 2 : 1, position: 'relative', minWidth: '330px', minHeight: '300px' }}
				>
					<CardMedia
						component="img"
						image={'/images/hero-black-card.png'}
						alt="Carte noire Pourliche"
						height={isNotTablet ? 300 : 'auto'}
						sx={{
							width: isNotTablet ? 360 : '70vw',
							maxWidth: '100%',
							zIndex: 2,
							position: 'absolute',
							left: isNotTablet ? 0 : '-2rem',
							marginBottom: isNotTablet ? '-3rem' : '1rem',
						}}
					/>
					<CardMedia
						component="img"
						image={'/images/hero-white-card.png'}
						alt="Carte blanche Pourliche"
						height={isNotTablet ? 260 : 'auto'}
						sx={{
							width: isNotTablet ? 350 : '70vw',
							maxWidth: '100%',
							zIndex: 1,
							position: 'absolute',
							left: isNotTablet ? '6rem' : '2rem',
							marginBottom: isNotTablet ? '-10rem' : '-6rem',
						}}
					/>
					{isNotTablet && (
						<CardMedia
							component="img"
							image={'/images/hero-view-thanks-s.png'}
							alt="Ecran de remerciement"
							height={620}
							sx={{ width: 360, maxWidth: '100%', marginRight: '-2rem' }}
						/>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default Hero
