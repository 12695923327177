import * as React from 'react'
import { useTheme, Box, useMediaQuery, CardMedia, Typography, Container, Link, List, ListItem } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'

export default function Footer() {
	const theme = useTheme()
	const isNotMobile = useMediaQuery('(min-width:600px)')

	return (
		<Container sx={{ padding: '0 2rem' }}>
			<Box
				component="footer"
				display="flex"
				gap="1rem"
				flexDirection={isNotMobile ? 'row' : 'column'}
				justifyContent="space-around"
				sx={{ py: 6 }}
			>
				<Box>
					<Typography variant="label">Contactez-nous</Typography>
					<List sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
						<ListItem sx={{ padding: 0 }}>
							<Link mailto={'bonjour@pourliche.fr'} display="flex" gap=".5rem" sx={{ wordBreak: 'break-word' }}>
								<EmailIcon sx={{ '&>path': { fill: theme.palette.primary.black } }} />
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									bonjour@pourliche.fr
								</Typography>
							</Link>
						</ListItem>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'https://instagram.com/pourliche'} target="_blank" display="flex" gap=".5rem">
								<CardMedia
									component="img"
									image={'/icons/instagram.svg'}
									alt="Instagram icon"
									width={23}
									height={23}
									sx={{ maxWidth: '23px' }}
								/>
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									Instagram
								</Typography>
							</Link>
						</ListItem>
					</List>
				</Box>
				<Box>
					<Typography variant="label">A propos</Typography>
					<List sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'/pages/notre-histoire'} color="text.secondary">
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									Notre histoire
								</Typography>
							</Link>
						</ListItem>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'/pages/condition-generale-utilisation'} color="text.secondary">
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									Conditions générales d'utilisation
								</Typography>
							</Link>
						</ListItem>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'/pages/condition-generale-vente'} color="text.secondary">
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									Conditions générales de vente
								</Typography>
							</Link>
						</ListItem>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'/pages/politique-confidentialite'} color="text.secondary">
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									Politique de confidentialité
								</Typography>
							</Link>
						</ListItem>
					</List>
				</Box>
				<Box>
					<Typography variant="label">Aide</Typography>
					<List sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'/faq'}>
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									FAQ
								</Typography>
							</Link>
						</ListItem>
						<ListItem sx={{ padding: 0 }}>
							<Link href={'https://shop.pourliche.fr'} target="_blank">
								<Typography variant="p" fontSize={16} color={theme.palette.primary.black}>
									E-Shop
								</Typography>
							</Link>
						</ListItem>
					</List>
				</Box>
			</Box>
			<Box align="center" m={2}>
				<Typography>
					<img src="/media/logo.png" alt="Pourliche" style={{ height: 69 }} sx={{ mt: 2 }} />
				</Typography>
				<Typography variant="p" fontSize={20}>
					Fait à Paris avec{' '}
					<Typography sx={{ color: theme.palette.primary.red }} display="inline">
						❤
					</Typography>
				</Typography>
			</Box>
		</Container>
	)
}
