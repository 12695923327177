import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FaqComponent from './faqComponent';
import { Container, Grid, Typography } from '@mui/material';

export default function FaqDrawer({value, user, lang}) {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{backgroundColor: '#F6F5FF', width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation" 
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <FaqComponent lang={lang} user={user}/>
    </Box>
  );

  return (
    <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Typography color="black" onClick={toggleDrawer(anchor, true)}>
            {value}
          </Typography>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{ backgroundColor: 'none!important'}}
          > 
          <Container sx={{backgroundColor: '#F6F5FF',m:0, p:0}}>
            <Grid maxWidth="lg" container sx={{backgroundColor: '#F6F5FF', p: 2, ml: 2}}>
              <Grid item xs={9}>
                <Typography variant="span"sx={{color: '#5E6264',fontWeight: 'bold'}}>Questions fréquentes</Typography>
              </Grid>
              <Grid item xs={3} sx={{textAlign: 'right', pr: 4}}>
                <Typography variant="span" onClick={toggleDrawer(anchor, false)}>Fermer</Typography>
              </Grid>
            </Grid>
            {list(anchor)}
          </Container>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}