import React from 'react'
import { useTheme, Box, Link, Typography, CardMedia, useMediaQuery } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import HowItWorksCard from '../../components/molecules/howItWorksCard'
import ContainedButton from '../../components/atoms/containedButton'

const HowItWorks = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotSmallMobile = useMediaQuery('(min-width:500px)')

	return (
		<Box id="howItsWorks" py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Box
				m={isNotTablet ? 4 : 0}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					maxWidth: '1120px',
				}}
			>
				<Box
					ml={isNotTablet ? 2 : 0}
					display="flex"
					gap="1rem"
					alignItems="center"
					justifyContent={isNotTablet ? 'start' : 'center'}
					width="100%"
				>
					<CardMedia
						component="img"
						image={'/icons/benefits-icon.svg'}
						alt="Benefits icon"
						width={34}
						height={40}
						sx={{ maxWidth: '34px' }}
					/>
					<Typography variant="h2" lineHeight={1.5} whiteSpace="nowrap">
						Comment ça marche ?
					</Typography>
				</Box>
				<Box display="flex" my={6} flexDirection={isNotTablet ? 'row' : 'column'} gap="2rem">
					<Box flex={1.5}>
						<CardMedia
							component="img"
							image={`/images/how-it-works-0${isNotTablet ? '-desktop' : ''}.png`}
							alt="Le pourboire simple et accessible"
							width="50vw"
							height="auto"
							sx={{ maxWidth: isNotTablet ? '626px' : '100vw' }}
						/>
					</Box>
					<Box
						mx={isNotTablet ? 0 : 4}
						flex={1}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						sx={{ maxWidth: isNotTablet ? '410px' : 'auto' }}
					>
						<Typography variant="h3">Le pourboire simple et accessible</Typography>
						<Typography variant="p">
							Faites scanner votre carte Pourliche à vos clients, ils accèderont à votre tirelire électronique.<br/>
							Et pourront ainsi vous laissez simplement un <b>pourboire par carte bancaire.</b>
						</Typography>
					</Box>
				</Box>
				<Box
					mx={isNotTablet ? 0 : 1}
					display="grid"
					gridTemplateColumns={isNotTablet ? '1fr 1fr 1fr' : '1fr'}
					gap={isNotTablet ? '2.5rem' : 0}
					sx={{ borderRadius: '18px', backgroundColor: isNotTablet ? 'transparent' : theme.palette.primary.lightRed }}
				>
					<HowItWorksCard>
						<Box
							height={isNotTablet ? 300 : 'auto'}
							display="flex"
							justifyContent={isNotTablet ? 'center' : 'flex-start'}
							gap="1rem"
							sx={{ width: '100%' }}
						>
							<CardMedia
								component="img"
								image={'/images/1.png'}
								alt="1"
								width={isNotSmallMobile ? '48px' : '24px'}
								height={isNotSmallMobile ? '58px' : '29px'}
								sx={{ maxWidth: isNotSmallMobile ? '48px' : '24px', marginTop: '.5rem' }}
							/>
							{isNotTablet ? (
								<CardMedia
									component="img"
									image={'/images/how-it-works-1-desktop.png'}
									alt="Comment ça marche - Procurez-vous la carte"
									width="197px"
									height="274px"
									sx={{ maxWidth: '197px' }}
								/>
							) : (
								<Typography variant="h3" fontWeight={700}>
									Rejoignez-vous
								</Typography>
							)}
						</Box>
						{isNotTablet ? (
							<Typography variant="h3" fontWeight={700} m={0}>
								Rejoignez-vous
							</Typography>
						) : (
							<CardMedia
								component="img"
								image={'/images/how-it-works-1.png'}
								alt="Comment ça marche - Procurez-vous la carte"
								width="100%"
								height="auto"
								sx={{ maxWidth: '60%', alignSelf: 'center' }}
							/>
						)}
						<Typography variant="p">Scannez votre carte Pourliche avec votre téléphone. Commencez votre inscription sans plus attendre !</Typography>
						<ContainedButton href="https://shop.pourliche.fr/" target="_blank" text="Accéder au store" secondary />
					</HowItWorksCard>
					<HowItWorksCard>
						<Box
							height={isNotTablet ? 300 : 'auto'}
							display="flex"
							justifyContent={isNotTablet ? 'center' : 'flex-start'}
							gap="1rem"
							sx={{ width: '100%' }}
						>
							<CardMedia
								component="img"
								image={'/images/2.png'}
								alt="2"
								width={isNotSmallMobile ? '48px' : '24px'}
								height={isNotSmallMobile ? '58px' : '29px'}
								sx={{ maxWidth: isNotSmallMobile ? '48px' : '24px', marginTop: '.5rem' }}
							/>
							{isNotTablet ? (
								<CardMedia
									component="img"
									image={'/images/how-it-works-2-desktop.png'}
									alt="Comment ça marche - Associez votre carte"
									width="131px"
									height="230px"
									sx={{ maxWidth: '131px', marginTop: '2rem' }}
								/>
							) : (
								<Typography variant="h3" fontWeight={700}>
									Associez votre tirelire
								</Typography>
							)}
						</Box>
						{isNotTablet ? (
							<Typography variant="h3" fontWeight={700} m={0}>
								Associez votre tirelire
							</Typography>
						) : (
							<CardMedia
								component="img"
								image={'/images/how-it-works-2.png'}
								alt="Comment ça marche - Associez votre carte"
								width="100%"
								height="auto"
								sx={{ maxWidth: '60%', alignSelf: 'center' }}
							/>
						)}
						<Typography variant="p">
							Il suffit d'associer votre tirelire électronique. <br/>
							Créez votre tirelire électronique si vous n'en n'avez pas déjà.
						</Typography>
						{isNotSmallMobile && (
							<>
								<Typography variant="smallButton" color={theme.palette.primary.grey}>
									Cagnottes fréquentes :{' '}
								</Typography>
								<Box display="flex" gap="1rem">
									<Box
										px={1.5}
										py={1}
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										gap="1rem"
										sx={{
											borderRadius: '6px',
											border: `1px solid ${theme.palette.primary.grey}`,
											background: theme.palette.primary.white,
										}}
									>
										<CardMedia
											component="img"
											image={'/images/lydia.png'}
											alt="Lydia"
											width={61}
											height={21}
											sx={{ maxWidth: '61px' }}
										/>
										<Link display="flex" href="https://www.lydia-app.com/" target="_blank">
											<LaunchIcon width={15} height={14} sx={{ '&>path': { fill: theme.palette.primary.grey } }} />
										</Link>
									</Box>
									<Box
										px={1.5}
										py={1}
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										gap="1rem"
										sx={{
											borderRadius: '6px',
											border: `1px solid ${theme.palette.primary.grey}`,
											background: theme.palette.primary.white,
										}}
									>
										<CardMedia
											component="img"
											image={'/images/paypal.png'}
											alt="Paypal"
											width={77}
											height={21}
											sx={{ maxWidth: '77px' }}
										/>
										<Link display="flex" href="https://www.paypal.com/fr" target="_blank">
											<LaunchIcon width={15} height={14} sx={{ '&>path': { fill: theme.palette.primary.grey } }} />
										</Link>
									</Box>
								</Box>
							</>
						)}
					</HowItWorksCard>
					<HowItWorksCard>
						<Box
							height={isNotTablet ? 300 : 'auto'}
							display="flex"
							justifyContent={isNotTablet ? 'center' : 'flex-start'}
							gap="1rem"
							sx={{ width: '100%' }}
						>
							<CardMedia
								component="img"
								image={'/images/3.png'}
								alt="3"
								width={isNotSmallMobile ? '48px' : '24px'}
								height={isNotSmallMobile ? '58px' : '29px'}
								sx={{ maxWidth: isNotSmallMobile ? '48px' : '24px', marginTop: '.5rem' }}
							/>
							{isNotTablet ? (
								<CardMedia
									component="img"
									image={'/images/how-it-works-3-desktop.png'}
									alt="Comment ça marche - Présentez votre carte"
									width="149px"
									height="265px"
									sx={{ maxWidth: '149px', marginTop: '1rem' }}
								/>
							) : (
								<Typography variant="h3" fontWeight={700}>
									Présentez votre carte
								</Typography>
							)}
						</Box>
						{isNotTablet ? (
							<Typography variant="h3" fontWeight={700} m={0}>
								Présentez votre carte
							</Typography>
						) : (
							<CardMedia
								component="img"
								image={'/images/how-it-works-3.png'}
								alt="Comment ça marche - Présentez votre carte"
								width="100%"
								height="auto"
								sx={{ maxWidth: '60%', alignSelf: 'center' }}
							/>
						)}
						<Typography variant="p">
							Faites scanner votre carte à vos clients, ils accèderont à votre tirelire électronique.<br/>
							Ils pourront alors simplement vous laisser un pourboire par carte bancaire.
						</Typography>
					</HowItWorksCard>
				</Box>
			</Box>
		</Box>
	)
}

export default HowItWorks
