import React, { useState, useContext } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import UserContext from '../../context/userContext'
import ContainedButton from '../../components/atoms/containedButton'

const ChangePassword = () => {
	const { user, setOpenSnack, setSnackMessage } = useContext(UserContext)
	const [infos, setInfos] = useState({
		oldPass: '',
		newPass: '',
		newPasswordConfirm: '',
	})
	const [errorMsg, setErrorMsg] = useState('')
	const [oldPassError, setOldPassError] = useState(false)
	const [newPassError, setNewPassError] = useState(false)
	const [confirmPassError, setConfirmPassError] = useState(false)

	const resetError = () => {
		setOldPassError(false)
		setNewPassError(false)
		setConfirmPassError(false)
		setErrorMsg('')
	}

	const handleChange = event => {
		resetError()
		const name = event.target.name
		const value = event.target.value
		setInfos(values => ({ ...values, [name]: value }))
	}

	async function resetRequest() {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/updateProfilePassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ` + localStorage.getItem('token') },
				body: JSON.stringify(infos),
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setOpenSnack(true)
					if (data.status) {
						setSnackMessage('Votre mot de passe a bien été mis à jour !')
					} else {
						setSnackMessage('Erreur lors de la mise à jour de votre mot de passe.')
					}
				})
		} catch (error) {
			console.log(error.message)
			setOpenSnack(true)
			setSnackMessage('Une erreur est survenue, veuillez réessayer ultérieurement.')
		}
	}

	const submitHandler = event => {
		event.preventDefault()
		if (infos.newPassword.length < 6) {
			setNewPassError(true)
			setErrorMsg('Le mot de passe doit avoir 6 caractères minimum')
			return
		} else if (infos.newPassword !== infos.newPasswordConfirm) {
			setNewPassError(true)
			setConfirmPassError(true)
			setErrorMsg('Les deux mot de passe ne correspondent pas.')
			return
		}
		resetRequest()
	}

	return (
		<Box px={2} sx={{ width: '100%', maxWidth: '500px' }}>
			<Typography mb={4} variant="h1">
				Changer votre mot de passe
			</Typography>
			<Box component="form" noValidate onSubmit={submitHandler}>
				<TextField
					error={oldPassError && errorMsg ? true : false}
					type="password"
					required
					fullWidth
					id="password"
					label="Mot de passe actuel"
					name="password"
					onChange={handleChange}
					autoComplete="current-password"
					InputLabelProps={{ shrink: true }}
					sx={{ width: 1, mt: 2, mb: 1 }}
					helperText={oldPassError && errorMsg ? errorMsg : undefined}
				/>
				<TextField
					error={newPassError && errorMsg ? true : false}
					type="password"
					required
					fullWidth
					id="newPassword"
					label="Nouveau mot de passe"
					name="newPassword"
					onChange={handleChange}
					autoComplete="new-password"
					InputLabelProps={{ shrink: true }}
					sx={{ width: 1, mt: 2, mb: 1 }}
					helperText={newPassError && errorMsg ? errorMsg : undefined}
				/>
				<TextField
					hidden
					error={confirmPassError && errorMsg ? true : false}
					type="password"
					required
					fullWidth
					id="newPasswordConfirm"
					label="Confirmer le nouveau mot de passe"
					name="newPasswordConfirm"
					onChange={handleChange}
					autoComplete="new-password"
					InputLabelProps={{ shrink: true }}
					sx={{ width: 1, mt: 2, mb: 1 }}
					helperText={confirmPassError && errorMsg ? errorMsg : undefined}
				/>

				<Box sx={{ width: 1, mt: 4, mb: 1 }} textAlign="center">
					<ContainedButton type="submit" text="Valider" secondary />
				</Box>
			</Box>
		</Box>
	)
}

export default ChangePassword
