import React from 'react'
import { useTheme, Button, Link, Typography, useMediaQuery } from '@mui/material'

const ContainedButton = ({ href, target, text, color, secondary, ...props }) => {
	const theme = useTheme()
	const isNotSmallMobile = useMediaQuery('(min-width:500px)')

	return (
		<Button
			variant="contained"
			sx={{
				height: secondary ? '42px' : isNotSmallMobile ? '62px' : '46px',
				background: color ? color : theme.palette.primary.black,
				padding: '0 2rem',
				minWidth: '180px',
				maxWidth: '260px',
				borderRadius: '10px',
			}}
			{...props}
		>
			<Link href={href} underline="none" target={target} rel="noreferrer" color={theme.palette.primary.white}>
				<Typography variant="button">{text}</Typography>
			</Link>
		</Button>
	)
}

export default ContainedButton
