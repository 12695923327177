import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { ThemeProvider } from '@mui/material/styles'
import Stack from '@mui/material/Stack'

export default function Pages() {
	const [data, setData] = useState([0])
	const [loading, setLoading] = useState(true)
	const url = window.location.href.split('/')[4]

	const breadcrumbs = [
		<Link underline="hover" key="1" color="inherit" href="/">
			Accueil
		</Link>,
	]

	useEffect(() => {
		getData()
	}, [])
	useEffect(() => {}, [data])

	const getData = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/pages/' + url, {})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setData(data)
				})
		} catch (error) {
			console.log(error.message)
		}
		setLoading(false)
	}, [])

	return (
		<ThemeProvider>
			<CssBaseline />
			<Header logo="black" backgroundColor="white" />
			<main>
				{/* Hero unit */}
				{!loading &&
					data.map(comp => (
						<Box
							sx={{
								pt: 12,
								pb: 6,
							}}
						>
							<Container maxWidth="lg">
								<Typography component="h1" variant="h2" color="text.primary" gutterBottom>
									{comp.title}
								</Typography>
								<Stack spacing={2}>
									<Breadcrumbs separator="›" aria-label="breadcrumb">
										{breadcrumbs}
										<Typography key="2" color="text.primary">
											{comp.title}
										</Typography>
									</Breadcrumbs>
								</Stack>
							</Container>
							<Container sx={{ py: 8, px: 2 }} maxWidth="lg">
								{/* End hero unit */}
								<Typography sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: comp.content }} />
							</Container>
						</Box>
					))}
			</main>
			<Footer></Footer>
		</ThemeProvider>
	)
}
