import React, { useState } from 'react'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import {
	useMediaQuery,
	FormHelperText,
	Button,
	CssBaseline,
	TextField,
	Link,
	Grid,
	Box,
	Typography,
	Container,
} from '@mui/material'

export default function Login() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [errorSubmit, setErrorSubmit] = useState(false)
	const [helperText, setHelperText] = useState('')

	const isNotTablet = useMediaQuery('(min-width:1100px)')

	const emailHandler = event => {
		setEmail(event.target.value)
	}

	const passwordHandler = event => {
		setPassword(event.target.value)
	}

	const submitHandler = event => {
		event.preventDefault()
		loginRequest()
	}

	async function loginRequest() {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/login', {
				method: 'POST',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json; charset=utf-8',
					// 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
				},
				body: JSON.stringify({
					email: email,
					password: password,
				}),
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					if (data.status) {
						localStorage.setItem('token', data.status)
						localStorage.setItem('user', JSON.stringify(data.user))
						window.location.replace('/settings')
					} else {
						setHelperText('Identifiant ou mot de passe erroné')
						setErrorSubmit(true)
					}
				})
		} catch (error) {
			setErrorSubmit(true)
			setHelperText('Erreur lors de la connexion')
			console.log(error.message)
		}
	}

	return (
		<>
			<Header logo="black" backgroundColor="transparent" />
			<Container component="main" maxWidth="lg" sx={{ py: 8 }}>
				<CssBaseline />
				<Grid container sx={{ mt: 8, mb: 4 }}>
					<Grid item xs={12} md={6} sx={{ display: { md: 'block', xs: 'none' } }}>
						<img src="/media/content/21683829.jpg" alt="Pourliche" style={{ width: '100%' }} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography
							component="p"
							variant="h5"
							sx={{ fontWeight: 'bold', fontSize: isNotTablet ? '2rem' : '2.5rem' }}
						>
							Se connecter au <br></br>compte Pourliche
						</Typography>

						<Grid item xs={12} md={6} sx={{ display: { md: 'none', xs: 'block' } }}>
							<img src="/media/content/21683829.jpg" alt="Pourliche" style={{ width: '100%' }} />
						</Grid>
						<Box component="form" onSubmit={submitHandler} noValidate sx={{ mt: 1 }}>
							<TextField
								error={errorSubmit}
								margin="normal"
								required
								fullWidth
								id="email"
								label="Adresse Email"
								name="email"
								autoComplete="email"
								autoFocus
								value={email}
								onChange={emailHandler}
							/>
							<TextField
								error={errorSubmit}
								margin="normal"
								required
								fullWidth
								name="password"
								label="Mot de Passe"
								type="password"
								id="password"
								autoComplete="current-password"
								value={password}
								onChange={passwordHandler}
							/>
							<FormHelperText sx={{ color: '#d32f2f' }}>{helperText}</FormHelperText>
							<Button type="submit" variant="contained" color="info" sx={{ mt: 3, mb: 2 }}>
								Connexion
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="/forgot" variant="body2">
										Mot de passe oublié ?
									</Link>
								</Grid>
								<Grid item>
									<Link href="https://shop.pourliche.fr" variant="body2">
										{'Pas encore de compte ?'}
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	)
}
