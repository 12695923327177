import React, { useState, useEffect, useCallback } from 'react'
import { 
	Box, 
	Container, 
	Typography, 
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqComponent = ({user,lang}) => {
	const [data, setData] = useState([0])
	const [loading, setLoading] = useState(true)
	const url = window.location.href.split('/')[4]
	
	useEffect(() => {
		getData()
	  }, [])
	  useEffect(() => {
	  }, [data])
	
	  const getData = useCallback(async () => {
		try {
		  await fetch(process.env.REACT_APP_BASE_URL + '/ws/faq/'+user+'/'+lang, {})
			.then(response => {
			  if (response.ok) {
				return response.json()
			  }
			  throw new Error('error')
			})
			.then(data => {
			  setData(data)
			})
		} catch (error) {
		  console.log(error.message)
		}
		setLoading(false)
	  }, [])
	

	return (
		<Container component="main" maxWidth="lg" sx={{backgroundColor: '#F6F5FF'}}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				
				<Container sx={{ pb: 6, px: 2 }}>
				{!loading && (
					<div>
					{data.map((comp,index) => (
						<Accordion key={index}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
						>
							<Typography>
							{comp.title}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography sx={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: comp.content}} />
						</AccordionDetails>
						</Accordion>
					))}
					</div>
				)}
				</Container>
			</Box>
		</Container>
	)
}

export default FaqComponent
