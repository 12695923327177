import React from 'react'
import { useTheme, Box, useMediaQuery } from '@mui/material'

const HowItWorksCard = ({ children }) => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotSmallMobile = useMediaQuery('(min-width:500px)')

	return (
		<Box
			px={isNotTablet ? 3 : isNotSmallMobile ? 6 : 3}
			py={isNotSmallMobile ? 6 : 3}
			gap="1rem"
			display="flex"
			flexDirection="column"
			sx={{ borderRadius: '18px', backgroundColor: theme.palette.primary.lightRed }}
		>
			{children}
		</Box>
	)
}

export default HowItWorksCard
