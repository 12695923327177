import * as React from 'react'
import { useState } from 'react'
import '../../styles/header.css'
import {
	useMediaQuery,
	useTheme,
	Box,
	CardMedia,
	Link,
	AppBar,
	Button,
	List,
	ListItem,
	Toolbar,
	Badge,
	ClickAwayListener,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ContainedButton from '../atoms/containedButton'

export default function Header({ logo, backgroundColor, linksValue }) {
	const isNotMobile = useMediaQuery('(min-width:768px)')
	const theme = useTheme()
	const [isNavExpanded, setIsNavExpanded] = useState(false)

	return (
		<AppBar
			className="navigation"
			elevation={0}
			sx={{
				backgroundColor: backgroundColor,
				display: 'flex',
				alignItems: 'center',
				padding: '0 1rem',
				'& > div': { padding: 0 },
			}}
		>
			<Toolbar
				m={4}
				className={isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'}
				sx={{ flexWrap: 'wrap', maxWidth: '1120px', width: '100%' }}
			>
				<Box nowrap="true" sx={{ flexGrow: 1 }}>
					<Link href="/" display="flex" alignItems="center">
						{logo === 'white' ? (
							<CardMedia
								component="img"
								image={'/media/logo-white.png'}
								alt="Pourliche"
								width={143}
								height={69}
								mt={2}
								mb={2}
								sx={{ maxWidth: '143px' }}
							/>
						) : (
							<CardMedia
								component="img"
								image={'/media/logo.png'}
								alt="Pourliche"
								width={143}
								height={69}
								mt={2}
								mb={2}
								sx={{ maxWidth: '143px', mt: 1, mb: 1 }}
							/>
						)}
					</Link>
				</Box>

				<Box className="ulContainer">
					<List
						sx={{
							fontFamily: 'Figtree',
							fontWeight: 600,
							display: 'flex',
							alignItems: 'center',
							gap: '1.5rem',
							'&>li>a:hover': { color: theme.palette.primary.blue },
						}}
					>
						<ListItem disablePadding>
							<Link
								href="https://shop.pourliche.fr/"
								target="_blank"
								variant="smallButton"
								sx={{ mx: 2, color: theme.palette.primary.black }}
							>
								E-Shop
							</Link>
						</ListItem>
						<ListItem disablePadding>
							<Link href="/faq" variant="smallButton" sx={{ my: 1, mx: 2, color: theme.palette.primary.black }}>
								FAQ
							</Link>
						</ListItem>
						{localStorage.getItem('token') ? (
							<>
								<ListItem disablePadding>
									<Link href="/settings" variant="smallButton" sx={{ my: 1, mx: 2 }}>
										{linksValue && <Badge badgeContent={'!'} color="error"></Badge>}
										Mon compte
									</Link>
								</ListItem>
							</>
						) : isNotMobile ? (
							<ContainedButton href="/login" target="_self" text="Se connecter" color={theme.palette.primary.blue} secondary />
						) : (
							<ListItem disablePadding>
								<Link href="/login" variant="smallButton" sx={{ my: 1, mx: 2, color: theme.palette.primary.black }}>
									Se connecter
								</Link>
							</ListItem>
						)}
					</List>
				</Box>
				{/* <ClickAwayListener onClickAway={() => setIsNavExpanded(false)}> */}
					<Button
						variant="text"
						className="hamburger"
						onClick={() => {
							setIsNavExpanded(!isNavExpanded)
						}}
						sx={{ color: theme.palette.primary.black }}
					>
						{linksValue && <Badge badgeContent={'!'} color="error"></Badge>}
						{<MenuIcon></MenuIcon>}
					</Button>
				{/* </ClickAwayListener> */}
			</Toolbar>
		</AppBar>
	)
}
