import React, { useState, useContext } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import UserContext from '../../context/userContext'
import ContainedButton from '../../components/atoms/containedButton'

const ForgotPassword = () => {
	const { setOpenSnack, setSnackMessage } = useContext(UserContext)
	const [email, setEmail] = useState('')
	const [errorMsg, setErrorMsg] = useState('')
	const [emailError, setEmailError] = useState(false)

	const handleChange = event => {
		setErrorMsg('')
		setEmailError(false)
		setEmail(event.target.value)
	}

	async function resetRequest() {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/reset', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ` + localStorage.getItem('token') },
				body: JSON.stringify({
					email: email,
				}),
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setOpenSnack(true)
					if (data.status === 4) {
						setSnackMessage('Vous avez déjà effectué une demande de réinitialisation. Il faut attendre 15 minutes entre chaque demande.')
					} else {
						setSnackMessage('Un e-mail de réinitialisation de mot de passe vous a été envoyé')
					}
				})
		} catch (error) {
			console.log(error.message)
			setOpenSnack(true)
			setSnackMessage('Une erreur est survenue, veuillez réessayer ultérieurement.')
		}
	}

	const submitHandler = event => {
		event.preventDefault()
		if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
			setEmailError(true)
			setErrorMsg('Email invalide.')
			return
		}
		resetRequest()
	}

	return (
		<Box px={2} sx={{ width: '100%', maxWidth: '500px' }}>
			<Typography mb={4} variant="h1">
				Mot de passe oublié
			</Typography>
			<Box component="form" onSubmit={submitHandler}>
				<TextField
					error={emailError && errorMsg ? true : false}
					required
					fullWidth
					id="email"
					label="Adresse Email"
					name="email"
					onChange={handleChange}
					InputLabelProps={{ shrink: true }}
					helperText={emailError && errorMsg ? errorMsg : undefined}
				/>
				<Box sx={{ width: 1, mt: 4, mb: 1 }} textAlign="center">
					<ContainedButton type="submit" text="Valider" secondary />
				</Box>
			</Box>
		</Box>
	)
}

export default ForgotPassword
