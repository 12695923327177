import React, { useState, useEffect, useCallback } from 'react'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Box, Typography, Link, Container } from '@mui/material'

export default function FaqPage() {
	const [data, setData] = useState([0])
	const [loading, setLoading] = useState(true)
	const url = window.location.href.split('/')[4]
	const breadcrumbs = [
		<Link href="/">
			Accueil
		</Link>,
		<Link href="/faq">
			FAQ
		</Link>,
	]

	useEffect(() => {
		getData()
	}, [])
	useEffect(() => { }, [data])

	const getData = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/faq/' + url, {})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setData(data)
				})
		} catch (error) {
			console.log(error.message)
		}
		setLoading(false)
	}, [])

	return (

		<div>
			{!loading && (
				<div>
					{data.map((comp, index) => (
						<Box py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="space-between"
								sx={{ width: '100%', maxWidth: '1120px' }}
							>
								<Header logo="black" backgroundColor="white" />
								<Container component="main" maxWidth="lg">
									<Box sx={{ mt: 10, mb: 4 }}>
										<Breadcrumbs separator="›" aria-label="breadcrumb">
											{breadcrumbs}
											<Typography color="text.primary">{comp.title}</Typography>
										</Breadcrumbs>
									</Box>
								</Container>


								<Container>
									<Typography component="h1" variant="h2" color="text.primary" gutterBottom>
										{comp.title}
									</Typography>
								</Container>

								<Container sx={{ py: 4, px: 2 }}>
									{/* End hero unit */}
									<Typography sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: comp.content }} />
								</Container>
								<Footer></Footer>
							</Box>
						</Box>
					))}
				</div>
			)}
		</div>
	)
}
