import React, { useState, useEffect, useCallback } from 'react'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import Breadcrumb from '../components/Breadcrumb'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMediaQuery, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

export default function Faq() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const isNotMobile = useMediaQuery('(min-width:600px)')

	useEffect(() => {
		getData()
	}, [])

	useEffect(() => { }, [data])

	const getData = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/faq/?lang=fr', {})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setData(data)
				})
		} catch (error) {
			console.log(error.message)
		}
		setLoading(false)
	}, [])

	return (
		<Box py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				sx={{ width: '100%', maxWidth: '1120px' }}
			>
				<Header logo="black" backgroundColor="white" />
				<Breadcrumb parent="" parentUrl="" child="FAQ" />

				<Box px={2} sx={{ width: '100%', position: 'relative' }}>
					<Typography component="h1" variant="h2" color="text.primary" gutterBottom>
						Foire aux questions
					</Typography>
				</Box>
				<Box
					gap={isNotMobile ? '1rem' : 0}
					sx={{ gridTemplateColumns: '1fr 1.5fr' }}
				>
					{!loading && (
						<div>
							{data.map((comp, index) => (
								<Accordion key={index}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography>
											{comp.title}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: comp.content }} />
									</AccordionDetails>
								</Accordion>
							))}
						</div>
					)}
				</Box>
				<Footer></Footer>
			</Box>
		</Box >
	)
}
