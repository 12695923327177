import * as React from 'react'
import { useState } from 'react'
import '../../styles/header.css'
import FaqDrawer from '../atoms/faqDrawer'
import {
	useMediaQuery,
	useTheme,
	Box,
	CardMedia,
	Link,
	AppBar,
	Button,
	List,
	ListItem,
	Toolbar,
	Badge,
	ClickAwayListener,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ContainedButton from '../atoms/containedButton'

export default function HeaderFaqDrawer({logo, backgroundColor, user, lang}) {
	const isNotMobile = useMediaQuery('(min-width:768px)')
	const theme = useTheme()
	const [isNavExpanded, setIsNavExpanded] = useState(false)

	return (
		<AppBar
			className="navigation"
			elevation={0}
			sx={{
				backgroundColor: backgroundColor,
				display: 'flex',
				alignItems: 'center',
				padding: '0 1rem',
				'& > div': { padding: 0 },
			}}
		>
			<Toolbar
				m={4}
				className={isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'}
				sx={{ flexWrap: 'wrap', maxWidth: '1120px', width: '100%' }}
			>
				<Box nowrap="true" sx={{ flexGrow: 1 }}>
					<Link href="/" display="flex" alignItems="center">
						{logo === 'white' ? (
							<CardMedia
								component="img"
								image={'/media/logo-white.png'}
								alt="Pourliche"
								width={143}
								height={69}
								mt={2}
								mb={2}
								sx={{ maxWidth: '143px' }}
							/>
						) : (
							<CardMedia
								component="img"
								image={'/media/logo.png'}
								alt="Pourliche"
								width={143}
								height={69}
								mt={2}
								mb={2}
								sx={{ maxWidth: '143px', mt: 1, mb: 1 }}
							/>
						)}
					</Link>
				</Box>
				<FaqDrawer user={user} lang={lang} value="Besoin d'aide ?"/>
			</Toolbar>
		</AppBar>
	)
}
