import React from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Button, useTheme, Box, Typography, useMediaQuery } from '@mui/material'
import OutlinedButton from '../../components/atoms/outlinedButton'
import ContainedButton from '../../components/atoms/containedButton'

const Tips = (scan) => {
	scan = scan.scan
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotMobile = useMediaQuery('(min-width:600px)')
	
	let params = useParams()

	const noPiggyBankApiHandler = (event) => {
		event.currentTarget.disabled = true;
		alert('C\'est notifié !')
		event.preventDefault()
		noPiggyBankApi()
	}
	
	async function noPiggyBankApi() {
	fetch(process.env.REACT_APP_BASE_URL + '/ws/no-piggy-bank/' + params.g, { method: 'GET' })
		.then(data => data.json()) // Parsing the data into a JavaScript object
		.then(json => console.log(JSON.stringify(json))) // Displaying the stringified data in an alert popup
	}


	return (
		<Box
			sx={{
				maxWidth: '600px',
				justifyContent: 'left',
				margin: '0 auto',
				pb: 8
			}}
		>
			<Box align="center">
				{scan.url === '' &&
				<Button variant="contained" color="error" onClick={noPiggyBankApiHandler}>
					Aucune tirelire associée<br/>
					Cliquez pour notifier
				</Button>
				}
				{scan.url !== '' &&
				<ContainedButton href={scan.url} target="_blank" text="Laisser un pourboire" secondary="secondary" sx={{p: 2}} />
				}
			</Box>
		</Box>
	)
}

export default Tips
