import React from 'react'
import { useTheme, Box, Typography, CardMedia, useMediaQuery } from '@mui/material'
import ContainedButton from '../../components/atoms/containedButton'

const EasyLife = () => {
	const theme = useTheme()
	const isNotMobile = useMediaQuery('(min-width:800px)')
	const isNotSmallMobile = useMediaQuery('(min-width:500px)')

	return (
		<>
			<Box id="easyLife" height={50}></Box>
			<Box
				mb={4}
				py={4}
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					backgroundColor: isNotMobile ? theme.palette.primary.lightGrey : 'transparent',
				}}
			>
				<Box
					pb={isNotSmallMobile ? (isNotMobile ? 0 : '6rem') : '10rem'}
					m={isNotMobile ? 4 : 0}
					display="flex"
					alignItems="end"
					position="relative"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						maxWidth: '1120px',
					}}
				>
					<CardMedia
						component="img"
						image={'/images/easy-life-1.png'}
						alt="Caisse pourboire"
						height="auto"
						sx={{ width: isNotMobile ? '70vw' : '100vw', maxWidth: isNotMobile ? '980px' : 'auto' }}
					/>
					<Box
						display="flex"
						gap="1rem"
						flexDirection="column"
						justifyContent="space-around"
						p={3}
						position="absolute"
						left={0}
						bottom={isNotMobile ? '4rem' : '-4rem'}
						width={isNotMobile ? 470 : '100%'}
						sx={{
							borderRadius: '16px',
							zIndex: 1,
							background: theme.palette.primary.white,
							boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.15)',
							maxWidth: isNotMobile ? '45vw' : 'auto',
						}}
					>
						<Typography variant="h2">Ne ratez plus aucun pourboire</Typography>
						<Typography variant="p" fontSize="clamp(20px,2vw,24px)">
							Fini la frustration de passer à côté d'un pourboire par carte bancaire grâce à notre service.
						</Typography>
						<ContainedButton href="https://shop.pourliche.fr/" target="_blank" text="Accéder au store" secondary />
					</Box>
				</Box>
			</Box>
			<Box
				py={4}
				mb={6}
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Box
					m={isNotMobile ? 4 : 0}
					pb={isNotSmallMobile ? (isNotMobile ? 0 : '6rem') : '12rem'}
					display="flex"
					alignItems="end"
					position="relative"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						maxWidth: '1120px',
					}}
				>
					<CardMedia
						component="img"
						image={'/images/easy-life-2.png'}
						alt="Caisse pourboire"
						height="auto"
						sx={{ width: isNotMobile ? '85vw' : '100vw', maxWidth: isNotMobile ? '980px' : 'auto' }}
					/>
					<Box
						display="flex"
						gap="1rem"
						flexDirection="column"
						justifyContent="space-around"
						p={3}
						position="absolute"
						left={isNotMobile ? '12vw' : 0}
						bottom={isNotMobile ? '-3rem' : '-4rem'}
						width={isNotMobile ? 470 : '100%'}
						sx={{
							borderRadius: '16px',
							zIndex: 1,
							background: theme.palette.primary.white,
							boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.15)',
							// boxShadow: isNotMobile ? '0px 4px 34px rgba(0, 0, 0, 0.15)' : '0',
							maxWidth: isNotMobile ? '45vw' : 'auto',
						}}
					>
						<Typography variant="h2">Pour tous les métiers de service</Typography>
						<Typography variant="p" fontSize="clamp(20px,2vw,24px)">
							Que vous exerciez dans la coiffure, la restauration, la beauté ou le bien-être, Pourliche répond à une
							problématique qui concerne chacun d'entre nous : celle d'accepter simplement les pourboires par carte bancaire.
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default EasyLife
